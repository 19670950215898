<template>
	<div class="account-section">
		<div class="row justify-content-center mb-5">
			<div class="col-md-9 col-lg-7 col-xl-6">
				<div class="card mb-4">
					<div class="card-body">
						<div class="row">
							<div class="col-12 col-md-auto mb-4 mb-md-0 text-center">
								<label for="person-photo" class="cursor-pointer position-relative on-parent mb-0">
									<img
										class="rounded-circle me-1"
										:src="account.photo"
										:alt="auth.name"
										width="125"
										height="125"
									/>
									<span class="emoji-icon icon-upload-photo show-on-hover">✏️</span>
								</label>
								<input type="file" id="person-photo" @change="uploadPhoto($event)" class="d-none" />
							</div>
							<div class="col">
								<h2 class="mb-3">
									<template v-if="account.name">{{ account.name }}</template>
									<i v-else class="text-neutral-400">(name not set)</i>
								</h2>

								<p class="mb-2"><span class="text-neutral-500">Email: </span> {{ account.email }}</p>
								<p class="mb-2"><span class="text-neutral-500">Phone: </span> {{ account.phone }}</p>
								<p v-if="account.bio" class="card-text">{{ account.bio }}</p>
							</div>
						</div>
					</div>
				</div>

				<div v-if="departmentsWithStripe.length" class="card mb-4">
					<div class="card-body">
						<h6 class="mb-3">Your saved payment methods</h6>

						<template v-if="ui.paymentMethods === 'loaded'">
							<div v-if="paymentMethods.length">
								<div class="row row-cols-1 row-cols-lg-2">
									<div
										v-for="paymentMethod in paymentMethods"
										:key="paymentMethod.id"
										class="col mb-3 position-relative"
									>
										<div class="dropdown three-dots position-absolute">
											<button
												class="btn btn-sm bg-neutral-200 border"
												role="button"
												data-bs-toggle="dropdown"
												aria-expanded="false"
											>
												<font-awesome-icon :icon="['fas', 'ellipsis-v']" />
											</button>
											<ul class="dropdown-menu">
												<li><h6 class="dropdown-header mt-2">Actions</h6></li>
												<li>
													<button
														class="dropdown-item text-danger"
														@click="removeStripePaymentMethod(paymentMethod)"
													>
														Remove
													</button>
												</li>
											</ul>
										</div>

										<div
											v-if="paymentMethod.type === 'us_bank_account'"
											class="bg-neutral-100 border p-3 rounded-1"
										>
											<div class="row mb-2">
												<div class="col">
													<font-awesome-icon
														:icon="['fas', 'building-columns']"
														class="me-1"
													/>
													****
													{{ paymentMethod.us_bank_account.last4 }}
													<span
														v-if="!paymentMethod.livemode"
														class="badge bg-warning-50 text-warning-400"
														>Test</span
													>
												</div>
											</div>
											<p class="mb-2">
												Bank: <strong>{{ paymentMethod.us_bank_account.bank_name }}</strong>
											</p>
											<p class="mb-0">
												Name: <strong>{{ paymentMethod.billing_details.name }}</strong>
											</p>
										</div>
										<div v-else class="bg-primary-50 border p-3 rounded-1">
											<pre>{{ paymentMethod }}</pre>
										</div>
									</div>
									<!-- <div class="col mb-3">
										<a
											href="#"
											class="d-block bg-light border border-dashed p-3 rounded-1 disabled"
										>
											<p class="mb-2">
												Add a card or bank account to pay licenses, permits or bills.
											</p>
											<p class="mb-0">
												<strong class="text-primary">➕ Add now</strong>
											</p>
										</a>
									</div> -->
								</div>
								<p class="mb-0">
									These cards and bank accounts can be used while you pay a license, permit, bill or
									reservation.
								</p>
							</div>
							<p v-else class="text-neutral-500 mb-0">
								You have no cards or bank accounts saved. They will appear here if you choose to save
								during a permit, license or bill payment.
							</p>
						</template>
						<!-- 
						<hr class="bg-primary-100" />

						<h6 class="mb-3">Payments history</h6> -->
					</div>
				</div>

				<ul class="list-group text-start rounded-1">
					<li class="list-group-item">
						<router-link to="/your-account/info" class="d-block text-dark"
							><font-awesome-icon :icon="['fas', 'user']" class="fa-fw me-1" /> Account info</router-link
						>
					</li>
					<li class="list-group-item">
						<router-link to="/your-account/login-options" class="d-block text-dark"
							><font-awesome-icon :icon="['fas', 'key']" class="fa-fw me-1" /> Login &amp;
							Password</router-link
						>
					</li>
					<li class="list-group-item">
						<router-link to="/your-account/privacy-security" class="d-block text-dark"
							><font-awesome-icon :icon="['fas', 'shield-alt']" class="fa-fw me-1" /> Privacy &amp;
							Security</router-link
						>
					</li>
					<li class="list-group-item">
						<router-link to="/your-account/notifications" class="d-block text-dark"
							><font-awesome-icon :icon="['far', 'bell']" class="fa-fw me-1" /> Notifications</router-link
						>
					</li>
					<li class="list-group-item">
						<span class="d-block text-dark cursor-pointer" @click="$store.dispatch('authLogout')"
							><font-awesome-icon :icon="['fas', 'sign-out-alt']" class="fa-fw me-1" /> Logout</span
						>
					</li>
					<li v-if="isStaff" class="list-group-item glowing">
						<router-link to="/your-account/debug" class="d-block text-dark"
							><font-awesome-icon :icon="['fas', 'code']" class="fa-fw me-1" /> Dev info</router-link
						>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
.icon-upload-photo {
	position: absolute;
	bottom: 5px;
	right: 5px;
}

.three-dots {
	position: absolute;
	top: 2px;
	right: 14px;
}
</style>

<script>
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'
import { random } from 'lodash-es'

import heyGovApi from '@/api.js'
import { handleResponseError } from '@/utils.js'

export default {
	name: 'AccountOverview',
	components: {},
	data() {
		return {
			ui: {
				paymentMethods: 'loading',
			},

			paymentMethods: [],
			setupIntent: null,
		}
	},
	computed: {
		...mapState(['j', 'jurisdictions', 'account', 'departments']),
		...mapGetters(['auth', 'isStaff']),
		departmentsWithStripe() {
			return (this.departments || []).filter(d => d.stripe_account || d.payments_platform === 'stripe')
		},
	},
	created() {
		this.$store.dispatch('loadDepartments').then(() => {
			if (this.departmentsWithStripe.length) {
				this.loadPaymentMethods()
			}
		})
	},
	methods: {
		loadPaymentMethods() {
			this.ui.paymentMethods = 'loading'

			heyGovApi('/account/me/payment-methods')
				.then(({ data }) => {
					this.paymentMethods = data.paymentMethods
					this.setupIntent = data.setupIntent
				})
				.catch(handleResponseError('Error loading payment methods ({error})'))
				.finally(() => {
					this.ui.paymentMethods = 'loaded'
				})
		},
		removeStripePaymentMethod(paymentMethod) {
			if (
				confirm(
					'Are you sure you want to remove this payment method? You will not be able to use it for future payments.'
				)
			) {
				heyGovApi
					.delete(`/account/me/payment-methods/${paymentMethod.id}`)
					.then(() => {
						this.paymentMethods = this.paymentMethods.filter(pm => pm.id !== paymentMethod.id)
					})
					.catch(handleResponseError('Error removing payment method ({error})'))
			}
		},

		uploadPhoto($event) {
			var form = new FormData()
			form.append('photo', $event.target.files[0])

			// upload the file
			heyGovApi.post(`/account/${this.auth.id}/photo`, form).then(
				({ data }) => {
					this.account.photo = data.photo + `?cache-buster=` + random(9999)
					Vue.toasted.show(`Your profile photo is updated`)
				},
				error => {
					Vue.toasted.show(error, { type: 'error' })
				}
			)
		},
	},
}
</script>
