<template>
	<div class="account-section">
		<p class="text-center">
			<router-link to="/your-account" class="btn btn-sm btn-outline-primary rounded"
				><font-awesome-icon :icon="['fas', 'arrow-left']" class="me-1" /> Account</router-link
			>
		</p>

		<div class="row justify-content-center mb-5">
			<div class="col-md-9 col-lg-7 col-xl-6">
				<div class="card">
					<div class="card-body">
						<h5>Privacy &amp; Security</h5>

						<ul class="list-group text-left mt-4">
							<li class="list-group-item">
								<span
									class="d-block cursor-pointer"
									@click="a('Please send an email at support@heygov.com with your request')"
									>Request account data</span
								>
							</li>
							<li class="list-group-item">
								<span
									class="d-block cursor-pointer"
									@click="a('Please send an email at support@heygov.com with your request')"
									>Delete your account</span
								>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
	name: 'AccountPrivacySecurity',
	components: {},
	data() {
		return {}
	},
	created() {},
	computed: {
		...mapState(['j', 'account']),
		...mapGetters(['auth'])
	},
	methods: {
		a(s) {
			alert(s)
		}
	}
}
</script>
