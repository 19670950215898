<template>
	<div class="account-page py-2">
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	name: 'Account',
	data() {
		return {}
	},
	created() {},
}
</script>
