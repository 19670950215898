var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"account-section"},[_c('p',{staticClass:"text-center"},[_c('router-link',{staticClass:"btn btn-sm btn-outline-primary rounded",attrs:{"to":"/your-account"}},[_c('font-awesome-icon',{staticClass:"me-1",attrs:{"icon":['fas', 'arrow-left']}}),_vm._v(" Account")],1)],1),_c('div',{staticClass:"row justify-content-center mb-5"},[_c('div',{staticClass:"col-md-11 col-lg-10 col-xl-8"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('h5',[_vm._v("Notification")]),_c('p',{staticClass:"mb-4"},[_vm._v("Choose which notifications you want to receive, and in which way:")]),(!_vm.account.photo)?_c('div',{staticClass:"alert alert-warning"},[_c('router-link',{attrs:{"to":"/your-account/info"}},[_vm._v("Add a phone number in your account")]),_vm._v(" if you want to receive notifications by text. ")],1):_vm._e(),_vm._m(0),_vm._m(1),(_vm.account && Object.keys(_vm.jurisdictionsJoined).length)?_c('div',_vm._l((_vm.jurisdictions),function(jurisdiction){return _c('div',{key:("notifications-jurisdiction-" + (jurisdiction.id))},[_c('p',{staticClass:"lead mb-1"},[_c('strong',[_vm._v(_vm._s(jurisdiction.name)+" notifications")])]),(_vm.jurisdictionsJoined[jurisdiction.id])?_c('div',{staticClass:"card-table mb-5"},[_c('table',{staticClass:"table table-hover"},[_c('tbody',[_vm._l((_vm.notificationTypes),function(notif,notifType){return [(
													notif.test(
														jurisdiction,
														_vm.jurisdictionsJoined[jurisdiction.id].role
													)
												)?_c('tr',{key:("j-" + (jurisdiction.id) + "-notif-" + notifType)},[_c('td',[_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(notif.name)+" "),(notif.staff)?_c('span',{staticClass:"badge bg-info-lighter text-info"},[_vm._v("Clerk alert")]):_vm._e()]),_c('p',{staticClass:"text-muted mb-0"},[_vm._v(_vm._s(notif.description))])]),_c('td',[_c('div',{staticClass:"form-check form-switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
																_vm.jurisdictionsJoined[jurisdiction.id].notifications[
																	notifType
																]
															),expression:"\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tjurisdictionsJoined[jurisdiction.id].notifications[\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tnotifType\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t]\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"}],staticClass:"form-check-input",attrs:{"type":"checkbox","value":"email","id":("j-" + (jurisdiction.id) + "-notif-" + notifType + "-email")},domProps:{"checked":Array.isArray(
																_vm.jurisdictionsJoined[jurisdiction.id].notifications[
																	notifType
																]
															)?_vm._i(
																_vm.jurisdictionsJoined[jurisdiction.id].notifications[
																	notifType
																]
															,"email")>-1:(
																_vm.jurisdictionsJoined[jurisdiction.id].notifications[
																	notifType
																]
															)},on:{"change":[function($event){var $$a=
																_vm.jurisdictionsJoined[jurisdiction.id].notifications[
																	notifType
																]
															,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="email",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.jurisdictionsJoined[jurisdiction.id].notifications, 
																	notifType
																, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.jurisdictionsJoined[jurisdiction.id].notifications, 
																	notifType
																, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.jurisdictionsJoined[jurisdiction.id].notifications, 
																	notifType
																, $$c)}},function($event){return _vm.updateNotifications(jurisdiction)}]}}),_c('label',{staticClass:"form-check-label",attrs:{"for":("j-" + (jurisdiction.id) + "-notif-" + notifType + "-email")}},[_vm._v("Email")])]),_c('div',{staticClass:"form-check form-switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
																_vm.jurisdictionsJoined[jurisdiction.id].notifications[
																	notifType
																]
															),expression:"\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tjurisdictionsJoined[jurisdiction.id].notifications[\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tnotifType\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t]\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"}],staticClass:"form-check-input",attrs:{"type":"checkbox","value":"push","id":("j-" + (jurisdiction.id) + "-notif-" + notifType + "-push")},domProps:{"checked":Array.isArray(
																_vm.jurisdictionsJoined[jurisdiction.id].notifications[
																	notifType
																]
															)?_vm._i(
																_vm.jurisdictionsJoined[jurisdiction.id].notifications[
																	notifType
																]
															,"push")>-1:(
																_vm.jurisdictionsJoined[jurisdiction.id].notifications[
																	notifType
																]
															)},on:{"change":[function($event){var $$a=
																_vm.jurisdictionsJoined[jurisdiction.id].notifications[
																	notifType
																]
															,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="push",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.jurisdictionsJoined[jurisdiction.id].notifications, 
																	notifType
																, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.jurisdictionsJoined[jurisdiction.id].notifications, 
																	notifType
																, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.jurisdictionsJoined[jurisdiction.id].notifications, 
																	notifType
																, $$c)}},function($event){return _vm.updateNotifications(jurisdiction)}]}}),_c('label',{staticClass:"form-check-label",attrs:{"for":("j-" + (jurisdiction.id) + "-notif-" + notifType + "-push")}},[_vm._v("App")])]),_c('div',{staticClass:"form-check form-switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(
																_vm.jurisdictionsJoined[jurisdiction.id].notifications[
																	notifType
																]
															),expression:"\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tjurisdictionsJoined[jurisdiction.id].notifications[\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tnotifType\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t]\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t"}],staticClass:"form-check-input",attrs:{"type":"checkbox","value":"sms","id":("j-" + (jurisdiction.id) + "-notif-" + notifType + "-sms")},domProps:{"checked":Array.isArray(
																_vm.jurisdictionsJoined[jurisdiction.id].notifications[
																	notifType
																]
															)?_vm._i(
																_vm.jurisdictionsJoined[jurisdiction.id].notifications[
																	notifType
																]
															,"sms")>-1:(
																_vm.jurisdictionsJoined[jurisdiction.id].notifications[
																	notifType
																]
															)},on:{"change":[function($event){var $$a=
																_vm.jurisdictionsJoined[jurisdiction.id].notifications[
																	notifType
																]
															,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="sms",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.jurisdictionsJoined[jurisdiction.id].notifications, 
																	notifType
																, $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.jurisdictionsJoined[jurisdiction.id].notifications, 
																	notifType
																, $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.jurisdictionsJoined[jurisdiction.id].notifications, 
																	notifType
																, $$c)}},function($event){return _vm.updateNotifications(jurisdiction)}]}}),_c('label',{staticClass:"form-check-label",attrs:{"for":("j-" + (jurisdiction.id) + "-notif-" + notifType + "-sms")}},[_vm._v("Text")])])])]):_vm._e()]})],2)])]):_c('p',{staticClass:"text-muted"},[_vm._v(" Please log out, then log in again to see all notification settings. ")])])}),0):_vm._e()])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"mb-1"},[_c('strong',[_vm._v("HeyGov")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-table mb-5"},[_c('table',{staticClass:"table table-hover"},[_c('tbody',[_c('tr',[_c('td',[_c('p',{staticClass:"mb-1"},[_vm._v("Account notifications")]),_c('span',{staticClass:"text-muted"},[_vm._v("Login, security and other important notifications about your account")])]),_c('td',[_c('div',{staticClass:"form-check"},[_c('input',{staticClass:"form-check-input",attrs:{"type":"checkbox","value":"","id":"n-account-email","checked":"","disabled":""}}),_c('label',{staticClass:"form-check-label",attrs:{"for":"n-account-email"}},[_vm._v("Email")])])])])])])])}]

export { render, staticRenderFns }