<template>
	<div class="account-section">
		<p class="text-center">
			<router-link to="/your-account" class="btn btn-sm btn-outline-primary rounded"
				><font-awesome-icon :icon="['fas', 'arrow-left']" class="me-1" /> Account</router-link
			>
		</p>

		<div class="row justify-content-center mb-5">
			<div class="col-md-9 col-lg-7 col-xl-6">
				<div class="card">
					<div class="card-body">
						<h5>Debug info</h5>

						<pre>{{ account }}</pre>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
	name: 'AccountDebug',
	created() {
		if (!this.isStaff) {
			this.$router.push('/account')
		}
	},
	computed: {
		...mapState(['account']),
		...mapGetters(['isStaff'])
	}
}
</script>
