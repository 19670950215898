<template>
	<div class="account-section">
		<p class="text-center">
			<router-link to="/your-account" class="btn btn-sm btn-outline-primary rounded"
				><font-awesome-icon :icon="['fas', 'arrow-left']" class="me-1" /> Account</router-link
			>
		</p>

		<div class="row justify-content-center mb-5">
			<div class="col-md-9 col-lg-7 col-xl-6">
				<div class="card">
					<div class="card-body">
						<h5>Login &amp; Password</h5>

						<p class="mb-4">Choose how you login &amp; secure your account:</p>

						<div class="border rounded p-3 mb-3 bg-primary-lighter">
							<p class="lead">✅ Login with link</p>
							<p class="mb-1">Fill your email on login page and receive a login link.</p>
						</div>

						<div
							class="border rounded p-3 mb-3 bg-light"
							:class="{ 'bg-primary-lighter': account.hasPassword }"
						>
							<p class="lead"><span v-if="account.hasPassword">✅</span> Login with password</p>
							<p>User email and password to login to HeyGov app.</p>

							<div v-if="error" class="alert alert-danger">⚠️ {{ error }}</div>
							<div v-if="success" class="alert alert-success">ℹ️ {{ success }}</div>

							<form v-if="states.password === 'edit'" @submit.prevent="savePass" class="mb-1">
								<div v-if="account.hasPassword" class="form-group mb-3">
									<label for="account-pass" class="form-label">Current password</label>
									<input
										type="password"
										class="form-control"
										id="account-pass"
										autocomplete="current-password"
										required
										minlength="8"
										v-model="passwords.password"
									/>
								</div>

								<div class="form-group mb-3">
									<label for="account-pass-new" class="form-label">New password</label>
									<input
										type="password"
										class="form-control"
										id="account-pass-new"
										autocomplete="new-password"
										required
										minlength="8"
										v-model="passwords.passwordNew"
									/>
								</div>

								<div class="form-group mb-3">
									<label for="account-pass-confirm" class="form-label">Confirm new password</label>
									<input
										type="password"
										class="form-control"
										id="account-pass-confirm"
										required
										minlength="8"
										v-model="passwords.passwordConfirm"
									/>
								</div>

								<button class="btn btn-primary">Save password</button>
							</form>
							<p v-else class="mb-1">
								<button class="btn btn-sm btn-dark" @click="states.password = 'edit'">
									{{ account.hasPassword ? 'Change password' : 'Create a password' }}
								</button>
							</p>
						</div>

						<div class="bg-light border rounded p-3 mb-3">
							<p class="lead">2 Factor Authentication <small class="text-muted">(coming soon)</small></p>
							<p class="mb-1">Protect your account with both your password and a code.</p>
						</div>

						<p class="card-text text-muted">
							<small
								>To change your email address, please head over to
								<router-link to="/your-account/info">Account Information</router-link></small
							>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import heyGovApi from '@/api.js'

export default {
	name: 'AccountLoginOptions',
	components: {},
	data() {
		return {
			states: {
				password: 'view',
			},
			passwords: {
				password: '',
				passwordNew: '',
				passwordConfirm: '',
			},
			error: '',
			success: '',
		}
	},
	computed: {
		...mapState(['j', 'account']),
		...mapGetters(['auth']),
	},
	methods: {
		savePass() {
			this.error = ''
			this.success = ''

			if (this.account.hasPassword && !this.passwords.password) {
				this.error = 'Please type your current password'
			} else if (!this.passwords.passwordNew) {
				this.error = 'Please type your new password'
			} else if (this.passwords.passwordNew !== this.passwords.passwordConfirm) {
				this.error = 'Make sure the confirmation password matches the new password'
			} else {
				heyGovApi.put('account/password', this.passwords).then(
					() => {
						this.states.password = 'view'
						this.account.hasPassword = true
						this.success = 'Your account password is updated'
					},
					error => {
						this.error = `Password couldn't be updated. ${error.response?.data?.detail || error}`
					}
				)
			}
		},
	},
}
</script>
