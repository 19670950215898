<template>
	<div class="account-section">
		<p class="text-center">
			<router-link to="/your-account" class="btn btn-sm btn-outline-primary rounded"
				><font-awesome-icon :icon="['fas', 'arrow-left']" class="me-1" /> Account</router-link
			>
		</p>

		<div class="row justify-content-center mb-5">
			<div class="col-md-10 col-lg-8 col-xl-7">
				<div class="card">
					<div class="card-body">
						<h5 class="mb-4">Account information</h5>

						<form @submit.prevent="saveAccount">
							<div class="form-group">
								<label for="account-first-name" class="form-label">Your name</label>

								<div class="row gx-2">
									<div class="col-12 col-lg-4">
										<div class="form-floating mb-3">
											<input
												type="text"
												class="form-control"
												id="account-first-name"
												v-model="account.first_name"
												required
												autocomplete="given-name"
											/>
											<label for="account-first-name">First name</label>
										</div>
									</div>
									<div class="col-12 col-lg-4">
										<div class="form-floating mb-3">
											<input
												type="text"
												class="form-control"
												id="account-middle-name"
												v-model="account.middle_name"
												autocomplete="additional-name"
											/>
											<label for="account-middle-name">Middle name</label>
										</div>
									</div>
									<div class="col-12 col-lg-4">
										<div class="form-floating mb-3">
											<input
												type="text"
												class="form-control"
												id="account-last-name"
												v-model="account.last_name"
												required
												autocomplete="family-name"
											/>
											<label for="account-last-name">Last name</label>
										</div>
									</div>
								</div>
							</div>

							<div class="form-group mb-3">
								<label for="account-email" class="form-label">Your email</label>
								<input
									type="email"
									class="form-control"
									id="account-email"
									autocomplete="email"
									required
									v-model="account.email"
								/>
							</div>

							<div class="form-group mb-3">
								<label for="account-bio" class="form-label">About you</label>
								<textarea
									class="form-control"
									id="account-bio"
									v-model="account.bio"
									rows="3"
									placeholder="Short bio or intro"
								></textarea>
							</div>

							<div class="form-group mb-3">
								<label for="account-phone" class="form-label">Your phone</label>
								<vue-phone-number-input
									v-model="pNumber"
									:default-country-code="defaultCountry"
									:preferred-countries="preferedCountries"
									class="mb-2"
									@update="setPhoneNumber"
									:border-radius="20"
								/>
							</div>

							<div class="form-group mb-2">
								<label for="account-dob" class="form-label">Date of birth</label>
								<input
									type="date"
									class="form-control"
									id="account-dob"
									autocomplete="dob"
									v-model="account.dob"
								/>
							</div>

							<div class="form-group mb-3">
								<label for="account-address" class="form-label">Address</label>
								<gmap-autocomplete @place_changed="setAccountAddress" :select-first-on-enter="true">
									<template v-slot:default="slotProps">
										<input
											id="account-address"
											class="form-control"
											ref="input"
											:value="account.address"
											placeholder="Your address"
											v-on="slotProps.listeners"
										/>
									</template>
								</gmap-autocomplete>
							</div>

							<div class="form-group mb-3">
								<label for="account-start-of-week" class="form-label">Start of week</label>
								<select class="form-select" v-model="account.start_of_week">
									<option value="Sun">Sunday</option>
									<option value="Mon">Monday</option>
								</select>
							</div>

							<p class="card-text text-center mt-4">
								<button class="btn btn-primary" :disabled="states.account === 'saving'">
									Update account info
								</button>
							</p>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import parsePhoneNumber from 'libphonenumber-js'
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
	name: 'AccountOverview',
	components: { VuePhoneNumberInput },
	data() {
		return {
			state: 'idle',
			states: {
				account: 'idle',
			},
			defaultCountry: 'US',
			preferedCountries: ['US', 'CA'],
			pNumber: '',
		}
	},
	created() {
		this.$store.dispatch('loadDepartments')
		this.preparePhoneNumber()
	},
	computed: {
		...mapState(['account']),
	},
	methods: {
		saveAccount() {
			this.states.account = 'saving'

			const data = {
				first_name: this.account.first_name,
				middle_name: this.account.middle_name,
				last_name: this.account.last_name,
				email: this.account.email,
				bio: this.account.bio,
				phone: this.account.phone,
				dob: this.account.dob,
				address: this.account.address,
				timezone: this.account.timezone,
				start_of_week: this.account.start_of_week,
			}

			if (data.dob && data.dob.includes('T')) {
				data.dob = data.dob.split('T').shift()
			}

			this.$store
				.dispatch('accountUpdate', data)
				.then(({ data }) => {
					this.states.account = 'idle'
					console.log(data)
				})
				.catch(() => {
					this.states.account = 'error'
				})
		},
		preparePhoneNumber() {
			if (this.account.phone) {
				const parsed = parsePhoneNumber(this.account.phone)

				if (parsed && parsed.isValid) {
					this.defaultCountry = parsed.country
					this.pNumber = parsed.nationalNumber
				} else {
					this.pNumber = this.account.phone
				}
			}
		},
		setPhoneNumber(payload) {
			if (payload.isValid) {
				const phone = '+' + payload.countryCallingCode + ' ' + payload.phoneNumber

				if (this.account.phone !== phone) {
					this.account.phone = phone
				}
			}
		},
		setAccountAddress(place) {
			this.account.address = place.formatted_address
		},
	},
}
</script>
