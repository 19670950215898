<template>
	<div class="account-section">
		<p class="text-center">
			<router-link to="/your-account" class="btn btn-sm btn-outline-primary rounded"
				><font-awesome-icon :icon="['fas', 'arrow-left']" class="me-1" /> Account</router-link
			>
		</p>

		<div class="row justify-content-center mb-5">
			<div class="col-md-11 col-lg-10 col-xl-8">
				<div class="card">
					<div class="card-body">
						<h5>Notification</h5>

						<p class="mb-4">Choose which notifications you want to receive, and in which way:</p>

						<div v-if="!account.photo" class="alert alert-warning">
							<router-link to="/your-account/info">Add a phone number in your account</router-link> if you
							want to receive notifications by text.
						</div>

						<p class="mb-1"><strong>HeyGov</strong></p>
						<div class="card-table mb-5">
							<table class="table table-hover">
								<tbody>
									<tr>
										<td>
											<p class="mb-1">Account notifications</p>
											<span class="text-muted"
												>Login, security and other important notifications about your
												account</span
											>
										</td>
										<td>
											<div class="form-check">
												<input
													class="form-check-input"
													type="checkbox"
													value=""
													id="n-account-email"
													checked
													disabled
												/>
												<label class="form-check-label" for="n-account-email">Email</label>
											</div>
										</td>
									</tr>
								</tbody>
							</table>
						</div>

						<div v-if="account && Object.keys(jurisdictionsJoined).length">
							<div
								v-for="jurisdiction in jurisdictions"
								:key="`notifications-jurisdiction-${jurisdiction.id}`"
							>
								<p class="lead mb-1">
									<strong>{{ jurisdiction.name }} notifications</strong>
								</p>

								<div v-if="jurisdictionsJoined[jurisdiction.id]" class="card-table mb-5">
									<table class="table table-hover">
										<tbody>
											<template v-for="(notif, notifType) in notificationTypes">
												<tr
													:key="`j-${jurisdiction.id}-notif-${notifType}`"
													v-if="
														notif.test(
															jurisdiction,
															jurisdictionsJoined[jurisdiction.id].role
														)
													"
												>
													<td>
														<p class="mb-1">
															{{ notif.name }}
															<span
																v-if="notif.staff"
																class="badge bg-info-lighter text-info"
																>Clerk alert</span
															>
														</p>
														<p class="text-muted mb-0">{{ notif.description }}</p>
													</td>
													<td>
														<div class="form-check form-switch">
															<input
																class="form-check-input"
																type="checkbox"
																value="email"
																@change="updateNotifications(jurisdiction)"
																:id="`j-${jurisdiction.id}-notif-${notifType}-email`"
																v-model="
																	jurisdictionsJoined[jurisdiction.id].notifications[
																		notifType
																	]
																"
															/>
															<label
																class="form-check-label"
																:for="`j-${jurisdiction.id}-notif-${notifType}-email`"
																>Email</label
															>
														</div>
														<div class="form-check form-switch">
															<input
																class="form-check-input"
																type="checkbox"
																value="push"
																:id="`j-${jurisdiction.id}-notif-${notifType}-push`"
																@change="updateNotifications(jurisdiction)"
																v-model="
																	jurisdictionsJoined[jurisdiction.id].notifications[
																		notifType
																	]
																"
															/>
															<label
																class="form-check-label"
																:for="`j-${jurisdiction.id}-notif-${notifType}-push`"
																>App</label
															>
														</div>
														<div class="form-check form-switch">
															<input
																class="form-check-input"
																type="checkbox"
																value="sms"
																:id="`j-${jurisdiction.id}-notif-${notifType}-sms`"
																@change="updateNotifications(jurisdiction)"
																v-model="
																	jurisdictionsJoined[jurisdiction.id].notifications[
																		notifType
																	]
																"
															/>
															<label
																class="form-check-label"
																:for="`j-${jurisdiction.id}-notif-${notifType}-sms`"
																>Text</label
															>
														</div>
													</td>
												</tr>
											</template>
										</tbody>
									</table>
								</div>
								<p v-else class="text-muted">
									Please log out, then log in again to see all notification settings.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import Vue from 'vue'

import heyGovApi from '@/api.js'

export default {
	name: 'AccountNotifications',
	components: {},
	data() {
		return {
			jurisdictionsJoined: {},

			notificationTypes: {
				alerts: {
					name: 'News & Notices',
					description: 'Receive news, notices and general updates',
					test() {
						return true
					},
				},
				emergencyAlerts: {
					name: 'Emergency Alerts',
					description: 'Receive emergency alerts from your municipality',
					test() {
						return true
					},
				},
				forms: {
					name: 'Licenses and permits',
					description: 'Receive alerts when your license and permit is approved, declined or expires',
					test(jurisdiction) {
						return jurisdiction.features.includes('forms')
					},
				},
				issues: {
					name: '311 request updates',
					description: "Get notified when there's an update for your 311 request",
					test(jurisdiction) {
						return jurisdiction.features.includes('issues')
					},
				},
				venues: {
					name: 'Venue reservations',
					description: 'Receive notifications about your pending and upcoming reservations',
					test(jurisdiction) {
						return jurisdiction.features.includes('venues')
					},
				},
				clerksForms: {
					name: 'HeyLicense',
					description: 'Receive alerts when a new application arrives in your department',
					staff: true,
					test(jurisdiction, role) {
						return jurisdiction.features.includes('forms') && role !== 'CITIZEN'
					},
				},
				clerksIssues: {
					name: 'Hey311',
					description: 'Receive alerts when a 311 request is assigned to your department',
					staff: true,
					test(jurisdiction, role) {
						return jurisdiction.features.includes('issues') && role !== 'CITIZEN'
					},
				},
				clerksVenues: {
					name: 'HeyReserve',
					description: 'Be notified when a new reservation is made for a venue in your department',
					staff: true,
					test(jurisdiction, role) {
						return jurisdiction.features.includes('venues') && role !== 'CITIZEN'
					},
				},
				clerksPayments: {
					name: 'HeyGov Pay',
					description: 'Receive alerts when a payment is made in your department',
					staff: true,
					test(jurisdiction, role) {
						return jurisdiction.features.includes('payments') && role !== 'CITIZEN'
					},
				},
			},
		}
	},
	computed: {
		...mapState(['j', 'account', 'jurisdictions']),
	},
	created() {
		this.loadJurisdictionsNotifications()
	},
	methods: {
		loadJurisdictionsNotifications() {
			heyGovApi('account/me/jurisdictions').then(
				({ data }) => {
					this.jurisdictionsJoined = data
				},
				error => {
					Vue.toasted.error(`Error loading notifications settings (${error.message})`)
				}
			)
		},

		updateNotifications(jurisdiction) {
			heyGovApi
				.put(`account/me/jurisdictions/${jurisdiction.id}`, {
					notifications: this.jurisdictionsJoined[jurisdiction.id].notifications,
				})
				.then(
					() => {
						Vue.toasted.success('🔔 Notifications updated')
					},
					error => {
						Vue.toasted.error(`Error saving notifications settings (${error.message})`)
					}
				)
		},
	},
}
</script>
